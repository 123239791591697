import React from "react";
import '../../../pages/Pages.css';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.css';
import ReactGA from "react-ga";
import { useEffect } from "react";
import {useNavigate} from 'react-router-dom';

function JavaStreamSideBar() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const navigate = useNavigate();

  const javaStream1 = () => {
    // 👇️ navigate to /contacts
    navigate('/Java/IO/1');
  };
  

    return (

      
    <div className="cards"> 

<Card className="bg-white text-black" onClick={javaStream1}>
          <Card.Title>How to read a text file in java ?</Card.Title>
          <Card.Text>
            Tag: Java 11, IO
          </Card.Text>

      </Card>

    </div>
    );
  }

export default JavaStreamSideBar;