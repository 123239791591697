const codes = {
	javaStream1: `
FileReader fileReader = new FileReader("inputFile.txt", StandardCharsets.UTF_8);
fileReader.read() // reads one byte at a time
`, 
  javaStream1_1: `
byte[] buffer = new byte[1024];
int length = 0;

for ((length = fileReader.read(buffer)) != -1) {
	System.out.println(length); // prints the length of the buffer

} `, 

javaStream1_2: `
try (FileReader fileReader = new FileReader("inputFile.txt", StandardCharsets.UTF_8) {
	byte[] buffer = new byte[1024];
	int length = 0;
	
	for ((length = fileReader.read(buffer)) != -1) {
		System.out.println(length); // prints the length of the buffer
	
	} 
}`,

javaStream1_3: `
import java.io.FileReader;
import java.io.IOException;

public class ReadFile {
    public static void main(String[] args) {
        try {
            FileReader fileReader = new FileReader("/inputFile.txt", StandardCharsets.UTF_8);
            char[] buffer = new char[1024];
            int length = 0;
            while ((length = fileReader.read(buffer)) != -1 ) {
                System.out.println(length);
            }
            fileReader.close(); // explicitly closing the resource

        } catch (IOException e) {
            throw new RuntimeException(e);
        }
    }

}`,

javaStream1_4: `
import java.io.FileReader;
import java.io.IOException;

public class ReadFile {
    public static void main(String[] args) {
        try (FileReader fileReader = new FileReader("inputFile.txt", StandardCharsets.UTF_8) {
            char[] buffer = new char[1024];
            int length = 0;
            while ((length = fileReader.read(buffer)) != -1 ) {
                System.out.println(length);
            }
        } catch (IOException e) {
            throw new RuntimeException(e);
        }
    }
}`
};

export default codes;
