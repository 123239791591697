import React from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as JavaIcons from "react-icons/lia"

export const SidebarData = [
    {
        title: "Overview", 
        path: "/overview", 
    }, 
    {
        title: "Java", 
        // path: "/java", 
        icon: <JavaIcons.LiaJava  />, 
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />, 

        subNav: [
            {
                title: "Java IO", 
                path: "services/services1", 
                icon: <IoIcons.IoIosPaper />,
                cName: "sub-nav",
            }
        ]

    },
    // {
    //     title: "Algorithms", 
    //     // path: "/java", 
    //     // icon: <JavaIcons.LiaJava  />, 
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />, 

    //     subNav: [
    //         {
    //             title: "BubbleSort", 
    //             path: "services/services1", 
    //             icon: <IoIcons.IoIosPaper />,
    //             cName: "sub-nav",
    //         }
    //     ]

    // }
    
]