import React from "react";

export const Services = () => {
    return (
        <div className="services">
            <h1>Mopelo services</h1>
        </div>
    );
};

export const ServicesOne = () => {
    return (
        <div className="servies">
            <h1>Mopelo services1</h1>
        </div>
    )
}