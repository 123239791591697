import styled from "styled-components";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

import "../components/Sidebar.css";
// import SearchBar from "./SearchBar";

const SidebarWrap = styled.div`
width: 100%;
`;

const Sidebar = () => {
    
    return (
        
            <nav className="nav">

                {/* <SearchBar className="searchBar"/> */}
                <div className="sidebar">
                    <SidebarWrap>

                        {SidebarData.map((item, index) => {
                            return <SubMenu item={item} key={index} />;
                        })}
                    </SidebarWrap>
                </div>
            </nav>
        
    );
};

export default Sidebar;