// pages and components
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { AboutUs, OurAim, OurVision } from './pages/AboutUs';
import Overview from "./pages/Overview";
import {
  Services
} from "./pages/Services";
import React from 'react';
import Navbar from './components/Navbar';
import Sidebar from "./components/Sidebar";
import ReactGA from "react-ga";
import { JavaStream1 } from "./pages/Java/Streams/JavaStream1";
import JavaStreamSideBar from "./pages/Java/Streams/JavaStreamSideBar";

const TRACKING_ID = "UA-178617012-1";
ReactGA.initialize(TRACKING_ID)


function App() {
  return (
    <Router>
      <Navbar />
      <Sidebar/>
      <Routes>
        <Route path='/' element={<Overview/>} />
        <Route path='/Overview' element={<Overview/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/services/services1' element={<JavaStreamSideBar/>} />
        <Route path='/java/IO/1' element={<JavaStream1/>} />
      </Routes>
    </Router>
  );
}

export default App;
