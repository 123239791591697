import React from "react";
import '../../../pages/Java/Streams/JavaStream1.css'; 
import { CopyBlock, dracula } from "react-code-blocks";
// import { sample, TopBar } from "../../../block-components";
// import Logo from "../../../block-components/Logo";
import ReactDOM from "react-dom";
import { sample } from "../../../block-components";



export const JavaStream1 = () => {
    // return (


//const [language, changeLanguage] = useState("java");
// const [languageDemo, changeDemo] = useState(sample["jsx"]);
// const [lineNumbers, toggleLineNumbers] = useState(true);
return (
  <div className="container mx-auto p-4">

{/* <div className="javaStream1"> */}
           
         {/* </div> */}
    <div className="demo">
    <h1>How to read a text file in java ?</h1>
    
    <div>
   <div> To read a file in java we need to establish a connection to the file. 
    This connection can be established by using the FileInputStream class or the FileReader class. </div>

<div>The FileInputStream provides binary-based data reading suitable for raw-data reading and the FileReader provides character-based reading suitable for text files. </div>

<div>The FileInputStream extends the InputStream abstract class and the FileReader extends the 

InputStreamReader abstract class. </div>

<div>These classes are located in the [java.io](http://java.io) and java.nio packages. </div>
    </div>

    <br />

         
      <CopyBlock
        //language='java'
        text={sample["javaStream1"]}
        // showLineNumbers={lineNumbers}
        language="java"
        theme={dracula}
        wrapLines={true}
        codeBlock
      />
      <br />

      <div>
      The read method reads binary data from a stream; returns the next byte of data, or -1 if the end of file is reached. It is an overloaded method and can also be used to populate a byte array. This array can then be used to write to a stream. 

This method throws an IOException if an I/O error occurs.
      </div>

      <br />

      <CopyBlock
        text={sample["javaStream1_1"]}
        language="java"
        codeBlock
        theme={dracula}
        wrapLines={true}
        showLineNumbers={true}
      />

<br />

      <div>The InputStream class implements the AutoCloseable interface. 

This means that we can define it in a  try-with-resources block. This ensures that the resources are promptly released. The close() method is automatically called when existing the try-with-resources block. 

If try-with-resources block is not used. The resources should be explicitly closed to avoid resource leakage.</div>

<br />


<CopyBlock
        text={sample["javaStream1_2"]}
        language="java"
        codeBlock
        theme={dracula}
        wrapLines={true}
        showLineNumbers={true}
      />
<div>

<br />

Complete code: 

without try-with-resources block
</div>

<br />

<CopyBlock
        text={sample["javaStream1_3"]}
        language="java"
        codeBlock
        theme={dracula}
        wrapLines={true}
        showLineNumbers={true}
      />

<br />

      <div>
      With try-with-resources block
      </div>

      <br />

      <CopyBlock
        text={sample["javaStream1_4"]}
        language="java"
        codeBlock
        theme={dracula}
        wrapLines={true}
        showLineNumbers={true}
      />


    </div>
  </div>
);
    // );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<JavaStream1 />, rootElement);

export default JavaStream1;
