import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
    display: flex;
    color: black;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    list-style: none;
    height: 10px;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        background: darkgrey;
        border-left: 4px solid black;
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    background: lightgrey;
    height: 22px;
    padding-left: 2rem;
    padding-top: 0.25em;
    padding: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    font-size: 18px;
    justify-content: space-between;

    &:hover {
        background: darkgrey;
        cursor: pointer;
        border-radius: 5px;
        align-items: center;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    
    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <SidebarLink to={item.path}
            onClick={item.subNav && showSubnav}>
                <div> 
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav 
                    ? item.iconOpened
                    : item.subNav
                    ? item.iconClosed
                    : null}
                </div>
            </SidebarLink>
            {subnav && 
            item.subNav.map((item, index) => {
                return (
                    <DropdownLink to={item.path} key={index}>
                        {/* {item.icon} */}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
                );
            })}
        </>
    );
};

export default SubMenu;