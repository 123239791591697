import "../components/Navbar.css";
import React, {useState} from 'react';
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import styled from "styled-components";

const SidebarWrap = styled.div`
width: 100%;
`;

const Navbar = () => {

    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false)

    const updateMenu = () => {
        if (!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hidden")
        }
        setIsMenuClicked(!isMenuClicked)
    
    }
    return (
        <div>
            <nav className="mopeloNav">
                <h1 className="navTitle">mopelo</h1>
                <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                </div>
            </nav>

            <div className={menu_class} onClick={updateMenu}>

                    <SidebarWrap>
                    {SidebarData.map((item, index) => {
                        return <SubMenu item={item} key={index} />;
                    })}
                    </SidebarWrap>

                </div>

          
        </div>
    )
}

export default Navbar